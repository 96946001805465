import "./CardEvolutionJourney.scss";

import { Link, useNavigate } from "react-router-dom";
import React, { FC, useState } from "react";

import { Button } from "reactstrap";
import Plyr from "plyr";
import VideoModal from "../VideoModal/VideoModal";

interface CardEvolutionJourneyProps {}

const CardEvolutionJourney: FC<CardEvolutionJourneyProps> = () => {
	const player = new Plyr("#player");
	const navigate = useNavigate();
	const [showModalVideo, setShowModalVideo] = useState<boolean>(false);

	return (
		<div className="CardEvolutionJourney" data-testid="CardEvolutionJourney">
			<div className="group">
				<div className="title">
					<span>Seja bem-vindo a UELF Evolution</span>
				</div>
				<div className="content">
					<div>
						<p className="text mt-5">
							O seu coach virtual de planejamento e performance da plataforma UELF.
							Aqui você encontra a ferramenta que precisa para planejar e definir seus
							objetivos de evolução pessoal, organizando as suas tarefas diárias.
						</p>
						<p className="text">
							O <b>UELF Evolution</b>, prioriza tudo por urgência e importância
							eliminando a procrastinação. Aliado a isso, possui um algoritmo que te
							mostra o quanto você está desafiando a sua zona de conforto para chegar
							onde deseja.
						</p>
						<p>
							Juntamente com o Valuation e o Education, o <b>UELF Evolution</b> é a
							ferramenta que faltava para que você se organizar e fazer o seu sucesso
							acontecer.
						</p>
					</div>
					<div className="graphic">
						<img src="assets/img/graphic.png" alt="" />
					</div>
				</div>
			</div>
			<div className="video">
				<span className="title">Próximos Passos</span>
				<Button
					className="btn-video-modal"
					onClick={() => {
						setShowModalVideo(true);
					}}
				>
					<div className="image">
						<img src="assets/video-icon.png" alt="Video Icon" />
					</div>
					<span>ASSISTA AO VÍDEO</span>
				</Button>
				{/* <div className="video-container">
					<div className="player">
						<video id="player" playsInline controls>
							<source
								src="https://deved.app.br/uelfhomologacao/api-v2/videos/uelf-evolution.mp4"
								type="video/mp4"
							/>
						</video>
					</div>
				</div> */}
			</div>

			<div className="button">
				<Link to={"/journey-map"}>
					<button type="button" className="a">
						<span>Voltar</span>
					</button>
				</Link>
				<Link to={"/macro-goals"}>
					<button type="button" className="two">
						<span>Visualizar</span>
					</button>
				</Link>
			</div>
			<VideoModal
				show={showModalVideo}
				onClose={setShowModalVideo}
				url={`${process.env.REACT_APP_UELF_API}/Storage/${process.env.REACT_APP_PUBLIC_KEY}/video2/current.mp4`}
			/>
		</div>
	);
};

export default CardEvolutionJourney;
