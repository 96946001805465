import "./Home.scss";
import "swiper/css";

import { Button, Col, Row } from "reactstrap";
import { Chart, Line } from "react-chartjs-2";
import zoomPlugin from "chartjs-plugin-zoom";
import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import MenuRoute from "../../routes/MenuRoute";
import ModalNews from "../../components/ModalNews/ModalNews";
import PainelMasterPage from "../../components/PainelMasterPage/PainelMasterPage";
import VideoModal from "../../components/VideoModal/VideoModal";
import annotationPlugin from "chartjs-plugin-annotation";
import { faHome, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { useActiveJourney } from "../../providers/ActiveJourneyProvider";
import { useAuth } from "../../providers/AuthProvider";
import { useDashboard } from "../../providers/DashboardProvider";
import { useMenu } from "../../providers/MenuProvider";
import GoogleTagManager, { EventEnum } from "../../services/GoogleTagManager";
import ModalChangePassword from "../../components/ModalChangePassword/ModalChangePassword";
import { ToastContainer } from "react-toastify";
import CardResourceHome from "../../components/CardResourceHome/CardResourceHome";

// import "plyr-react/dist/plyr.css";

const Home = () => {
	const [activeMenu, setActiveMenu] = useState<any>(null);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [showModalNews, setShowModalNews] = useState<boolean>(false);
	const { chartJornadas } = useDashboard();
	const [showModalVideo, setShowModalVideo] = useState<boolean>(false);

	const chartRef = React.useRef<any>(null);
	Chart.register(zoomPlugin);

	const { rules } = useMenu();
	const { activeJourney, startJourney, dataUser, finishJourney } =
		useActiveJourney();
	const { slide, setSlide, firstLogin } = useAuth();

	const ruleStepJorney = async () => {
		for (var i = 0; i < rules.length; i++) {
			if (!rules[i].status && rules[i].nome !== "diagnose") {
				switch (rules[i].nome) {
					case "purpose":
						setActiveMenu(MenuRoute[i]);
						break;
					case "survey":
						setActiveMenu(MenuRoute[i]);
						break;
					case "survey-finish":
						setActiveMenu(MenuRoute[i]);
						break;
					case "todoavoid":
						setActiveMenu(MenuRoute[i]);
						break;
					case "goals":
						setActiveMenu(MenuRoute[i]);
						break;
					case "education":
						setActiveMenu(MenuRoute[i]);
						break;
					case "evolution":
						setActiveMenu(MenuRoute[i]);
						break;
					case "performance":
						setActiveMenu(MenuRoute[i]);
						break;
					default:
						break;
				}

				return;
			}
		}
	};

	const resetZoomDashboardGraph = () => {
		if (chartRef && chartRef.current) {
			chartRef.current.resetZoom();
		}
	};

	const validateRoute = (obj: any, plano: number) => {
		let menus: any = ["education", "evolution"];

		if (dataUser.idPlano <= 1 && menus.includes(obj?.icon.toLowerCase())) {
			return "/performance";
		}

		return obj?.layout;
	};

	useEffect(() => {
		GoogleTagManager.event(EventEnum.Marketing, {
			action: dataUser?.jornadasConcluidas ? "home_grafico" : "home_boas_vindas",
		});
		if (rules) {
			if (activeJourney != null) {
				if (activeJourney > 0) {
					ruleStepJorney().then();
				} else {
					setActiveMenu(MenuRoute[0]);
				}
			}
		}
	}, [activeJourney, rules]);

	useEffect(() => {
		if (activeMenu) {
			setIsLoading(false);
		}
	}, [activeMenu]);

	useEffect(() => {
		finishJourney().then();
	}, []);

	Chart.register(annotationPlugin);

	return (
		<>
			<ModalChangePassword show={firstLogin} />
			<PainelMasterPage
				title={"Home"}
				subtitle={""}
				subtitleclass={""}
				icon={<FontAwesomeIcon icon={faHome} />}
				onClick={() => {}}
			>
				<div className="homepage">
					<div className="card">
						<Row className="justify-content-center">
							{/* <Col className="col col-12 col-md-4">
								<img src="assets/img/home.svg" alt="UELF" />
							</Col> */}
							<Col className="col col-12 col-md-12 align-items-center d-flex">
								<div className="text-content">
									<h2 className="title">Olá {dataUser?.usuario?.nome}</h2>
									<div className="text">
										<p className="mb-0">
											Estamos muito felizes em te ver por aqui. Temos certeza de que você
											acaba de tomar uma excelente decisão rumo ao seu sucesso pessoal.
											Assista ao nosso vídeo de boas vindas e saiba como iniciar a sua
											experiência UELF.
										</p>
										{/* {dataUser?.jornadasConcluidas?.length > 0 ||
										dataUser?.jornadaEmAndamento ? (
											<p className="mb-0">
												Olá, que bom que você voltou. Caso ainda tenha alguma dúvida sobre o
												funcionamento do UELF clique em ”Assistir vídeo de boas vindas”,
												caso queira continuar de onde você parou, clique em ”próximos
												passos”.
											</p>
										) : (
											<p className="mb-0">
												Estamos muito felizes em te ver por aqui. Temos certeza de que você
												acaba de tomar uma excelente decisão rumo ao seu sucesso pessoal.
												Assista ao nosso vídeo de boas vindas e saiba como iniciar a sua
												experiência UELF.
											</p>
										)} */}
									</div>
								</div>
							</Col>
						</Row>
						<Row>
							<Col className="col-12 justify-content-end d-flex">
								<div className="actions">
									{!isLoading && (
										<>
											{/* {dataUser?.jornadasConcluidas?.length ? (
												<>
													<Button
														className="btn-video-modal"
														onClick={() => {
															setShowModalVideo(true);
														}}
													>
														<span>Assistir vídeo de boas-vindas</span>
													</Button>
													{dataUser?.jornadaEmAndamento ? (
														""
													) : (
														<Button onClick={() => startJourney()}>
															<span>Refazer Valuation</span>
														</Button>
													)}
												</>
											) : (
												""
											)}

											{dataUser?.plusoft?.URL && (
												<a
													href={`${dataUser.plusoft.URL}`}
													target="_blank"
													rel="noreferrer"
													className="btn btn-secondary"
												>
													<span>Acessar UELF Education</span>
												</a>
											)}

											{!dataUser?.jornadaEmAndamento &&
											dataUser?.jornadasConcluidas?.length &&
											dataUser?.idPlano == 1 &&
											dataUser?.evolution == 1 ? (
												<Link to={"/journey-evolution"} style={{ display: "contents" }}>
													<Button>
														<span>Continuar para Evolution</span>
													</Button>
												</Link>
											) : (
												""
											)}

											{dataUser?.jornadasConcluidas?.length &&
											!dataUser?.jornadaEmAndamento ? (
												<>
													<Link
														to={validateRoute(activeMenu, dataUser?.idPlano)}
														style={{ display: "contents" }}
													>
														<Button>
															<span>Seu Resultado</span>
														</Button>
													</Link>

													{dataUser?.idPlano == 2 ? (
														<Link
															to={validateRoute(activeMenu, dataUser?.idPlano)}
															style={{ display: "contents" }}
														>
															<Button>
																<span>Continuar para Evolution</span>
															</Button>
														</Link>
													) : (
														""
													)}
												</>
											) : dataUser?.jornadaEmAndamento ? (
												<Link
													to={validateRoute(activeMenu, dataUser?.idPlano)}
													style={{ display: "contents" }}
												>
													<Button>
														<span>Continuar Jornada</span>
													</Button>
												</Link>
											) : (
												""
											)}

											{!dataUser?.jornadaEmAndamento &&
											!dataUser?.jornadasConcluidas?.length ? (
												<Button onClick={() => startJourney()}>
													<span>Próximos passos</span>
												</Button>
											) : (
												""
											)} */}

											{!dataUser?.jornadaEmAndamento &&
											dataUser?.jornadasConcluidas?.length ? (
												<Button onClick={() => startJourney()}>
													<span>Refazer Valuation</span>
												</Button>
											) : (
												""
											)}

											{!dataUser?.jornadaEmAndamento &&
											dataUser?.jornadasConcluidas?.length ? (
												<Link to="/performance" style={{ display: "contents" }}>
													<Button>
														<span>Seu Resultado</span>
													</Button>
												</Link>
											) : (
												""
											)}

											{!dataUser?.jornadaEmAndamento &&
											!dataUser?.jornadasConcluidas?.length ? (
												<Button onClick={() => startJourney()}>
													<span>Começar</span>
												</Button>
											) : (
												""
											)}

											{dataUser?.jornadaEmAndamento ? (
												<Link
													to={validateRoute(activeMenu, dataUser?.idPlano)}
													style={{ display: "contents" }}
												>
													<Button>
														<span>Continuar Jornada</span>
													</Button>
												</Link>
											) : (
												""
											)}
										</>
									)}
								</div>
							</Col>
						</Row>
					</div>
					<div className="card graph-video">
						<Swiper
							spaceBetween={100}
							slidesPerView={1}
							pagination={{ clickable: true }}
							initialSlide={slide}
							// autoHeight={true}
						>
							{dataUser?.jornadasConcluidas?.length && chartJornadas?.data ? (
								<SwiperSlide>
									<div className="chart-container">
										<div className="chart-header">
											<h2 className="title">Minha Evolução</h2>
										</div>
										<div className="chart-notes">
											<h1 className="title-pontuacao-home">
												<span>Nota da Vida: </span>
												<span className="note">
													{dataUser?.ultimaJornadaNotas?.pontuacao > 0 &&
													dataUser?.ultimaJornadaNotas?.pontuacao !== undefined
														? dataUser?.ultimaJornadaNotas?.pontuacao?.toLocaleString("pt-br")
														: " -- "}
												</span>
											</h1>
											<h1 className="title-pontuacao-home">
												<span>Nível de Performance: </span>
												<span className="note">
													{dataUser?.ultimaJornadaNotas?.notaVida > 0 &&
													dataUser?.ultimaJornadaNotas?.notaVida !== undefined
														? dataUser?.ultimaJornadaNotas?.notaVida?.toLocaleString(
																"pt-br",
																{ minimumFractionDigits: 2 },
														  )
														: " -- "}
												</span>
											</h1>
											{/* <h1 className="title-pontuacao-home">
												<span>Média: </span>
												<span className="note">
													{dataUser?.ultimaJornadaNotas?.media > 0 &&
													dataUser?.ultimaJornadaNotas?.media !== undefined
														? dataUser?.ultimaJornadaNotas?.media?.toLocaleString("pt-br", {
																minimumFractionDigits: 2,
														  })
														: " -- "}
												</span>
											</h1> */}

											<div>
												<Button
													title="Zoom Inicial"
													onClick={() => resetZoomDashboardGraph()}
												>
													<FontAwesomeIcon icon={faMagnifyingGlass} />
												</Button>
											</div>
										</div>
										<div className="chart">
											<Line
												ref={chartRef}
												data={chartJornadas?.data}
												options={chartJornadas?.options}
											/>
										</div>
									</div>
								</SwiperSlide>
							) : (
								""
							)}

							{!dataUser?.jornadasConcluidas?.length ? (
								<SwiperSlide>
									<div className="mb-3">
										<h5 className="title">Os recursos que nós trabalhamos</h5>
									</div>

									<div className="my-5">
										<div className="cards-resource">
											<CardResourceHome idRecurso={1} recurso={"Dinheiro"} />
											<CardResourceHome idRecurso={2} recurso={"Saúde"} />
											<CardResourceHome idRecurso={3} recurso={"Tempo"} />
											<CardResourceHome idRecurso={4} recurso={"Conhecimento"} />
											<CardResourceHome idRecurso={5} recurso={"Emoções"} />
										</div>
									</div>

									<div className="mb-3">
										<h5 className="title">Iniciando um plano de performance</h5>
									</div>
									<Button
										className="btn-video-modal-slide"
										onClick={() => {
											setShowModalVideo(true);
										}}
									>
										<div className="image">
											<img src="assets/video-icon.png" alt="Video Icon" />
										</div>
										<span> ASSISTA AO VÍDEO</span>
									</Button>
								</SwiperSlide>
							) : (
								""
							)}
						</Swiper>
					</div>
				</div>
			</PainelMasterPage>
			<ModalNews show={showModalNews} onClose={setShowModalNews} />
			<VideoModal
				show={showModalVideo}
				onClose={setShowModalVideo}
				url={`${process.env.REACT_APP_UELF_API}/Storage/${process.env.REACT_APP_PUBLIC_KEY}/video1/current.mp4`}
			/>
			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
		</>
	);
};

// @ts-ignore
export default Home;
