import "./Diagnose.scss";

import { useEffect, useState } from "react";

import { Button } from "reactstrap";
import CardGradesResources from "../../components/JourneyStatistics/CardGradesResources/CardGradesResources";
import DiagnoseService from "../../services/DiagnoseService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import Loading from "../../components/Loading/Loading";
import PainelMasterPage from "../../components/PainelMasterPage/PainelMasterPage";
import Plyr from "plyr";
import { ToastContainer } from "react-toastify";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { toastSettings } from "../../models/toastConfig";
import { useActiveJourney } from "../../providers/ActiveJourneyProvider";

// import "plyr-react/dist/plyr.css";

const Diagnose = () => {
	const player = new Plyr("#player");
	const [data, setData] = useState<any[]>();
	const [isLoading, setIsLoading] = useState<boolean>(true);

	const { activeJourney } = useActiveJourney();

	useEffect(() => {
		if (activeJourney) {
			setIsLoading(true);
			handleList();
		}
	}, [activeJourney]);

	const handleList = async () => {
		try {
			const [_Response, _Error] = await new DiagnoseService().list();

			if (!!_Error || !_Response?.status) {
				toastSettings(_Error || _Response?.message, "bottom-center", "error");
				setIsLoading(false);
				return;
			}

			setData(_Response.resultSet);

			setIsLoading(false);
		} catch (err) {
			console.warn(err);
			setIsLoading(false);
		}
	};

	const renderDiagnose = (data: any[]) => {
		return (
			<div className="diagnose">
				<div className="row">
					<div className="col resource-notes p-0">
						<CardGradesResources
							data={data}
							page="diagnose"
							titleTutorial="Iniciando um Plano de Performance"
							videoTutorial="https://www.youtube.com/watch?v=cpMSBnGSoFc"
						/>
					</div>
				</div>
			</div>
		);
	};

	return (
		<PainelMasterPage
			title={"Pontos de Atenção"}
			subtitle={
				"De acordo com as suas respostas, o UELF Valuation indica resumidamente que você mantenha atenção nos pontos abaixo."
			}
			subtitleclass="text-white"
			icon={<FontAwesomeIcon icon={faCheck} />}
			onClick={() => {}}
			helpText="Essas são as suas 4 notas mais baixas, com base nas suas respostas anteriores. Vamos ajudá-lo a identificar por onde começar para melhorar suas notas."
		>
			{!isLoading ? (
				<>{data && renderDiagnose(data)}</>
			) : (
				<Loading margin="my-6" />
			)}
			<div className="actions-wrapper">
				{/* <Link to="/survey">
					<Button className="step-backward">Voltar</Button>
				</Link> */}
				<Link to="/todoavoid">
					<Button color="dark" className="step-forward">
						Listas de evitar e fazer
					</Button>
				</Link>
			</div>
			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
		</PainelMasterPage>
	);
};

export default Diagnose;
