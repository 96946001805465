import "./HeaderPage.scss";

import { Col, Container, Row } from "reactstrap";
import React, { MouseEventHandler, useState } from "react";
import ReactPixel from "react-facebook-pixel";

import Breadcrumb from "../Breadcrumb/Breadcrumb";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion } from "@fortawesome/free-regular-svg-icons";
import ModalHelper from "../ModalHelper/ModalHelper";

interface Props {
	title: string;
	subtitle: string;
	titleclass: string;
	subtitleclass: string;
	icon: JSX.Element;
	onClick: MouseEventHandler<HTMLButtonElement>;
	helpText?: string;
}

const HeaderPage = ({
	title,
	subtitle = "",
	titleclass = "",
	subtitleclass = "",
	icon = <></>,
	onClick,
	helpText,
}: Props) => {
	ReactPixel.init("744591990099086");
	ReactPixel.pageView();
	ReactPixel.track("track");

	const [modalHelper, setModalHelper] = useState<boolean>(false);

	return (
		<>
			<div className="header">
				<Container fluid>
					<div className="header-body">
						<Row className="align-items-center">
							<Col xs="12" lg="6">
								<h1 className="title">
									{icon}
									{title}
									{helpText && (
										<div className="helper">
											<FontAwesomeIcon
												icon={faCircleQuestion}
												onClick={() => setModalHelper(true)}
												title="Ajuda"
											/>
										</div>
									)}
								</h1>
								<div className="text">
									<p>{subtitle}</p>
								</div>
							</Col>
							<Col lg="6" xs="5"></Col>
						</Row>
						{title.toLowerCase() !== "home" && <Breadcrumb namePage={title} />}
					</div>
				</Container>
			</div>
			<ModalHelper
				show={modalHelper}
				helpText={helpText}
				onClose={setModalHelper}
			/>
		</>
	);
};

export default HeaderPage;
