import { Route, Routes } from "react-router-dom";

import AccountRecovery from "../pages/AccountRecovery/AccountRecovery";
import { ActiveJourneyProvider } from "../providers/ActiveJourneyProvider";
import { AuthenticationGate } from "./PrivateRoute";
import { AuthenticationAdminGate } from "./PrivateRouteAdmin";
import Dashboard from "../pages/Dashboard/Dashboard";
import { DashboardProvider } from "../providers/DashboardProvider";
import Diagnose from "../pages/Diagnose/Diagnose";
import EditProfile from "../pages/EditProfile/EditProfile";
import EducationJourney from "../pages/EducationJourney/EducationJourney";
import EvolutionJourney from "../pages/EvolutionJourney/EvolutionJourney";
import Feelings from "../pages/Feelings/Feelings";
import Goals from "../pages/Goals/Goals";
import Home from "../pages/Home/Home";
import JourneyBackpack from "../pages/JourneyBackpack/JourneyBackpack";
import { JourneyBackpackProvider } from "../providers/JourneyBackpackProvider";
import JourneyMap from "../pages/JouneyMap/JourneyMap";
import Login from "../pages/Login/Login";
import MacroGoals from "../pages/MacroGoals/MacroGoals";
import { MacroGoalsProvider } from "../providers/MacroGoalsProvider";
import { MenuProvider } from "../providers/MenuProvider";
import Notes from "../pages/Notes/Notes";
import PageNotFound from "../pages/PageNotFound/PageNotFound";
import Payment from "../pages/Payments/Payments";
import { PaymentsProviders } from "../providers/PaymentsProviders";
import Performance from "../pages/Performance/Performance";
import PerformancePainel from "../pages/PerformancePanel/PerformancePainel";
import ProgressTracker from "../pages/ProgressTracker/ProgressTracker";
import { ProgressTrackerProvider } from "../providers/ProgressTrackerProvider";
import Purpose from "../pages/Purpose/Purpose";
import React from "react";
import { StagesProvider } from "../providers/StagesProvider";
import SuccessRoadmap from "../pages/SuccessRoadmap/SuccessRoadmap";
import { SuccessRoadmapProvider } from "../providers/SuccessRoadmapProvider";
import Survey from "../pages/Survey/Survey";
import SurveyAssessment from "../pages/SurveyAssessment/SurveyAssessment";
import SurveyFinish from "../pages/SurveyFinish/SurveyFinish";
import { SurveyProvider } from "../providers/SurveyProvider";
import Timeline from "../pages/Timeline/Timeline";
import ToDoAvoid from "../pages/ToDoAvoid/ToDoAvoid";
import { ToDoAvoidProvider } from "../providers/ToDoAvoidProvider";
import ExternalPayment from "../pages/ExternalPayment/ExternalPayment";
import { ExternalPaymentProvider } from "../providers/ExternalPaymentProvider";
import LoginAdmin from "../pages/LoginAdmin/LoginAdmin";
import { AuthAdminProvider } from "../providers/AuthAdminProvider";
import HomeAdmin from "../pages/HomeAdmin/HomeAdmin";
import { DashboardAdminProvider } from "../providers/DashboardAdminProvider";
import DashboardAdmin from "../pages/DashboardAdmin/DashboardAdmin";
import DetailedSearchAdmin from "../pages/DetailedSearchAdmin/DetailedSearchAdmin";
import PerformanceAdmin from "../pages/PerformanceAdmin/PerformanceAdmin";
import ConfigurationAdmin from "../pages/ConfigurationAdmin/ConfigurationAdmin";

const RootRoute = () => {
	return (
		<AuthAdminProvider>
			<DashboardAdminProvider>
				<ActiveJourneyProvider>
					<DashboardProvider>
						<MenuProvider>
							<StagesProvider>
								<SurveyProvider>
									<MacroGoalsProvider>
										<SuccessRoadmapProvider>
											<ProgressTrackerProvider>
												<JourneyBackpackProvider>
													<ToDoAvoidProvider>
														<PaymentsProviders>
															<ExternalPaymentProvider>
																<Routes>
																	<Route path="/" element={<Login />} />
																	<Route path="/login" element={<Login />} />
																	<Route
																		path="/external-payment"
																		element={<ExternalPayment />}
																	/>
																	<Route
																		path="/account-recovery/:token"
																		element={<AccountRecovery />}
																	/>

																	<Route path="/admin/login" element={<LoginAdmin />} />
																	<Route element={<AuthenticationAdminGate />}>
																		<Route path="/admin/" element={<HomeAdmin />} />
																		<Route path="/admin/dashboard" element={<DashboardAdmin />} />
																		<Route
																			path="/admin/detailed-search"
																			element={<DetailedSearchAdmin />}
																		/>
																		<Route
																			path="/admin/diagnose/:userId"
																			element={<PerformanceAdmin />}
																		/>
																		<Route
																			path="/admin/configuration"
																			element={<ConfigurationAdmin />}
																		/>
																	</Route>

																	<Route path="*" element={<PageNotFound />} />
																	<Route element={<AuthenticationGate />}>
																		<Route path="/home" element={<Home />} />
																		<Route path="/edit-profile" element={<EditProfile />} />
																		<Route path="/purpose" element={<Purpose />} />
																		<Route path="/survey" element={<Survey />} />
																		<Route path="/feelings" element={<Feelings />} />
																		<Route
																			path="/survey-assessment"
																			element={<SurveyAssessment />}
																		/>
																		<Route path="/diagnose" element={<Diagnose />} />
																		<Route path="/todoavoid" element={<ToDoAvoid />} />
																		<Route path="/goals" element={<Goals />} />
																		<Route path="/survey-finish" element={<SurveyFinish />} />
																		<Route path="/performance" element={<Performance />} />
																		<Route path="/journey-map" element={<JourneyMap />} />
																		<Route
																			path="/journey-evolution"
																			element={<EvolutionJourney />}
																		/>
																		<Route
																			path="/journey-education"
																			element={<EducationJourney />}
																		/>
																		<Route
																			path="/performance-painel"
																			element={<PerformancePainel />}
																		/>
																		<Route path="/macro-goals" element={<MacroGoals />} />
																		<Route path="/goals-and-tasks" element={<SuccessRoadmap />} />
																		<Route
																			path="/progress-tracker"
																			element={<ProgressTracker />}
																		/>
																		<Route path="/timeline" element={<Timeline />} />
																		<Route
																			path="/journey-backpack"
																			element={<JourneyBackpack />}
																		/>
																		<Route path="/notes" element={<Notes />} />
																		<Route path="*" element={<PageNotFound />} />
																		<Route path="/payments" element={<Payment />} />
																		<Route path="/dashboard" element={<Dashboard />} />
																	</Route>
																</Routes>
															</ExternalPaymentProvider>
														</PaymentsProviders>
													</ToDoAvoidProvider>
												</JourneyBackpackProvider>
											</ProgressTrackerProvider>
										</SuccessRoadmapProvider>
									</MacroGoalsProvider>
								</SurveyProvider>
							</StagesProvider>
						</MenuProvider>
					</DashboardProvider>
				</ActiveJourneyProvider>
			</DashboardAdminProvider>
		</AuthAdminProvider>
	);
};

export default RootRoute;
