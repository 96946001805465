import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import "./ModalHelper.scss";

interface ModalHelperProps {
	show: boolean;
	onClose?: any;
	helpText?: string;
}

const ModalHelper = ({ show, onClose, helpText }: ModalHelperProps) => {
	const [modal, setModal] = useState<boolean>(false);

	const toggle = () => setModal(!modal);
	const handleOnClose = () => onClose(false);

	useEffect(() => {
		if (show) {
			setModal(show);
		}
	}, [show]);

	return (
		<Modal
			isOpen={modal}
			toggle={toggle}
			className="ModalHelper"
			data-testid="ModalHelper"
			onClosed={handleOnClose}
			size="sm"
			centered
		>
			<ModalHeader>Ajuda</ModalHeader>
			<ModalBody className="d-flex p-0">
				<p dangerouslySetInnerHTML={{ __html: helpText ?? "" }} />
			</ModalBody>
		</Modal>
	);
};

export default ModalHelper;
