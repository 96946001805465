import "./ConfigurationAdmin.scss";

import { useEffect, useState, FC, useRef } from "react";
import Footer from "../../components/Footer/Footer";
import HeaderAdmin from "../../components/HeaderAdmin/HeaderAdmin";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import AdminService from "../../services/AdminService";
import { toastSettings } from "../../models/toastConfig";
import { Button, Col, Input, Row } from "reactstrap";
import Loading from "../../components/Loading/Loading";
import { ToastContainer } from "react-toastify";

const ConfigurationAdmin: FC = () => {
	const [isLoading, setLoading] = useState<boolean>(false);

	const inputImageRef = useRef<any>(null);
	const inputVideoRef1 = useRef<any>(null);
	const inputVideoRef2 = useRef<any>(null);
	const inputVideoRef3 = useRef<any>(null);

	const handleImageClick = () => {
		inputImageRef.current.click();
	};
	const handleVideoClick = (id: Number) => {
		switch (id) {
			case 1:
				inputVideoRef1.current.click();
				break;
			case 2:
				inputVideoRef2.current.click();
				break;
			case 3:
				inputVideoRef3.current.click();
				break;
			default:
				break;
		}
	};

	const onImageChange = async (event: any) => {
		if (event.target.files && event.target.files[0]) {
			let img = event.target.files[0];
			setLoading(true);
			const result = (await AdminService.editLogo(img))[0];
			setLoading(false);
			if (result?.status) {
				toastSettings(
					"Logotipo atualizado com sucesso!",
					"bottom-center",
					"success",
				);
			}
		}
	};

	const onVideoChange = (id: Number) => async (event: any) => {
		if (event.target.files && event.target.files[0]) {
			let video = event.target.files[0];
			setLoading(true);
			const result = (await AdminService.editVideo(video, id))[0];
			setLoading(false);
			if (result?.status) {
				toastSettings("Vídeo atualizado com sucesso!", "bottom-center", "success");
			}

			event.target.value = "";
		}
	};

	return (
		<>
			<div className="ConfigurationAdmin">
				<div className="container-fluid">
					<HeaderAdmin
						title="Configuração"
						subtitle=""
						icon={<FontAwesomeIcon icon={faGear} />}
					/>

					<div className="mt-5">
						<Row>
							<Col md={3}>
								<div className="card p-3">
									<div className="menu">
										<h2 role={"button"}>Aparência</h2>
									</div>
								</div>
							</Col>
							<Col md={9}>
								<div className="card p-3">
									<div className="d-flex gap-3">
										<div className="m-1 mr-5">
											<h2>Logotipo</h2>
										</div>
										<div>
											<Button className="btn-secondary" onClick={handleImageClick}>
												Importar Arquivo
											</Button>
											<input
												ref={inputImageRef}
												style={{ display: "none" }}
												type="file"
												onChange={onImageChange}
												accept="image/png, image/jpeg"
											/>
										</div>
									</div>
									<div className="d-flex gap-3">
										<div className="m-1 mr-5">
											<h2>Vídeo de Boas-Vindas</h2>
										</div>
										<div>
											<Button
												className="btn-secondary"
												onClick={() => handleVideoClick(1)}
											>
												Importar Arquivo
											</Button>
											<input
												ref={inputVideoRef1}
												style={{ display: "none" }}
												type="file"
												onChange={onVideoChange(1)}
												accept="video/mp4"
											/>
										</div>
									</div>
									<div className="d-flex gap-3">
										<div className="m-1 mr-5">
											<h2>Vídeo do Evolution</h2>
										</div>
										<div>
											<Button
												className="btn-secondary"
												onClick={() => handleVideoClick(2)}
											>
												Importar Arquivo
											</Button>
											<input
												ref={inputVideoRef2}
												style={{ display: "none" }}
												type="file"
												onChange={onVideoChange(2)}
												accept="video/mp4"
											/>
										</div>
									</div>
									<div className="d-flex gap-3">
										<div className="m-1 mr-5">
											<h2>Vídeo do Education</h2>
										</div>
										<div>
											<Button
												className="btn-secondary"
												onClick={() => handleVideoClick(3)}
											>
												Importar Arquivo
											</Button>
											<input
												ref={inputVideoRef3}
												style={{ display: "none" }}
												type="file"
												onChange={onVideoChange(3)}
												accept="video/mp4"
											/>
										</div>
									</div>
									{isLoading && <Loading />}
								</div>
							</Col>
						</Row>
					</div>
				</div>
			</div>
			<Footer />
			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
		</>
	);
};

// @ts-ignore
export default ConfigurationAdmin;
